import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Clm2Img from "../../assets/Icons/mail.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
// import ogimage from "../../assets/Images/ogImages/OG_Thank_you-ContactUS.png";
import BannerImage from "../../assets/Images/Homepage/LocationBanners/Chennai.png";
import Layout from "../../components/layouts/layout";
// import { BASE_SITE_URL } from "../../shared/constants";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import VideoBanner from "../../templates/VideoBanner";
import Infoview from "../../templates/InfoView";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import Typography from "@material-ui/core/Typography";
import Button from "../../components/Button";

const Chennai = () => {
  /**
   * Video Banner
   */

  const VideobannerData = {
    variant: "contained",
    color: "primary",
    displayButton: "no",
    ImageBannerTitle: "Chennai",
    ImageBannerSubTitle: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. <br />
        {/* <div className="contact-banner-margin"> */}
        Lorem Ipsum has been the industry’s standard dummy text ever since the
        1500s.
        {/* </div> */}
      </>
    ),
    BannerImage: BannerImage,
    // route: "/thank-you",
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  const InfoviewData = {
    InfoviewContent: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry’s standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </>
    ),
  };
  return (
    <Layout
      seo={{
        title: "Chennai",
        ogtitle: "Chennai",
        description: "Welcome to Chennai",
      }}
    >
      <GatsbySeo noindex={true} />
      <VideoBanner
        VideobannerData={VideobannerData}
        wrapClassName="custom-banner-wrap"
      />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Contact Us"
          routepath="/contact-us"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Contact Us
        </Typography>
      </div>
      <div className="">&nbsp;</div>
      <Infoview InfoviewData={InfoviewData} />

      <div className="mt-70">
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default Chennai;
